@use '@angular/material' as mat;
@import 'theme';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
  background: #ddd;
}

a {
  color: mat.get-color-from-palette($niji-mado-accent);
}
.accent {
  color: mat.get-color-from-palette($niji-mado-accent);
}
.primary {
  color: mat.get-color-from-palette($niji-mado-primary);
}

.dark-theme {
  a {
    color: mat.get-color-from-palette($niji-mado-accent-dark);
  }
  .accent {
    color: mat.get-color-from-palette($niji-mado-accent-dark);
  }
  .primary {
    color: mat.get-color-from-palette($niji-mado-primary-dark);
  }
}

// see https://frasco.io/writing-css-with-accessibility-in-mind-4fc82b26aecb
.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

// dirty hack for angular/material
mat-drawer-content {
  overflow-x: hidden !important;
}
